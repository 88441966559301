<template>
  <b-row class="checklist">
    <b-col>
      <h3>{{ title }}, delmoment nr. {{ page }}</h3>
      <b-table striped hover :fields="fields" :items="items">
        <template v-slot:cell(name)="data">
          {{ data.value.firstname | capitalize }}
          {{ data.value.lastname | capitalize }}
        </template>
        <template v-slot:cell(personsIn)="data">
          <b-form-checkbox size="lg" v-for="slot in data.value" v-bind:key="slot" class="float-left"></b-form-checkbox>
        </template>
        <template v-slot:cell(personsOut)="data">
          <b-form-checkbox size="lg" v-for="slot in data.value" v-bind:key="slot" class="float-left"></b-form-checkbox>
        </template>
      </b-table>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Checklist',
  props: {
    page: Number,
    title: String,
    data: Array
  },
  data: function () {
    return {
      fields: [
        {
          key: 'id',
          label: 'Id'
        },
        {
          key: 'name',
          label: 'Namn'
        },
        {
          key: 'personsIn',
          label: 'Inträde'
        },
        {
          key: 'personsOut',
          label: 'Utträde'
        }
      ]
    }
  },
  computed: {
    items: function () {
      let elements = []
      this.data.forEach(element => {
        elements.push({
          id: element.id,
          name: {
            firstname: element.firstname,
            lastname: element.lastname
          },
          personsIn: element.reservedSlots,
          personsOut: element.reservedSlots
        })
      })
      return elements
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      let retVal = ''
      value
        .toString()
        .toLowerCase()
        .split(' ')
        .forEach(v => {
          if (retVal.length > 0) {
            retVal += ' '
          }
          retVal += v.charAt(0).toUpperCase() + v.slice(1)
        })
      return retVal
    }
  }
}
</script>

<style lang="scss">
h3 {
  padding-left: 10px;
  padding-right: 10px;
}
.checklist {
  page-break-before: always;
}
</style>
