import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userName: '',
    roles: ''
  },
  mutations: {
    setUserName (state, userName) {
      state.userName = userName
    },
    setRoles (state, roles) {
      state.roles = roles
    }
  },
  getters: {
    userName: state => state.userName,
    roles: state => state.roles
  }
})
