<template>
  <b-row>
    <b-col>
      <h3>{{ title }}</h3>
      <b-table striped hover :fields="fields" :items="items">
        <template v-slot:cell(name)="data">
          {{ data.value.firstname | capitalize }}
          {{ data.value.lastname | capitalize }}
        </template>
        <template v-slot:cell(notMembers)="data">
          <span v-if="data.value > 0">{{ data.value }} st</span>
          <span v-else></span>
        </template>
        <template v-slot:cell(reservedSlots)="data">
          <span v-if="data.value > 0">{{ data.value }} st</span>
          <span v-else></span>
        </template>
        <template v-slot:cell(total)="data">
          <span v-if="data.value > 0">{{ data.value }} kr</span>
          <span v-else></span>
        </template>
        <template v-slot:custom-foot="data">
          <b-tr>
            <b-td>Totalt</b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td>{{ notMembers.length }} st</b-td>
            <b-td>{{ reservedCount }} st</b-td>
            <b-td></b-td>
          </b-tr>
      </template>
      </b-table>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Overview',
  props: {
    title: String,
    data: Array
  },
  computed: {
    items: function () {
      let elements = []
      this.data.forEach(element => {
        elements.push({
          group: element.group,
          id: element.id,
          name: {
            firstname: element.firstname,
            lastname: element.lastname
          },
          notMembers: element.notMembers,
          reservedSlots: element.reservedSlots,
          total: element.total
        })
      })
      return elements
    },
    notMembers: function () {
      return this.items.filter(item => item.notMembers > 0)
    },
    reservedCount: function () {
      let count = 0;
      this.items.forEach(item =>
        count += 1 + item.notMembers
      );
      return count;
    }
  },
  data: function () {
    return {
      fields: [
        {
          key: 'group',
          label: 'Grupp'
        },
        {
          key: 'id',
          label: 'Id'
        },
        {
          key: 'name',
          label: 'Namn'
        },
        {
          key: 'notMembers',
          label: 'Utomstående'
        },
        {
          key: 'reservedSlots',
          label: 'Reserverade'
        },
        {
          key: 'total',
          label: 'Betalat'
        }
      ]
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      let retVal = ''
      value
        .toString()
        .toLowerCase()
        .split(' ')
        .forEach(v => {
          if (retVal.length > 0) {
            retVal += ' '
          }
          retVal += v.charAt(0).toUpperCase() + v.slice(1)
        })
      return retVal
    }
  }
}
</script>

<style scoped lang="scss">
h3 {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
