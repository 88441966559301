import Keycloak from 'keycloak-js';
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

let initOptions = {
  url: 'https://dashboard.svbefs.se/auth/',
  realm: 'FV-LINK',
  clientId: 'svbefs-checklist-vue',
  onLoad: 'login-required'
}

const keycloak = new Keycloak(initOptions);

keycloak.onTokenExpired = () => {
  //console.log("Token expired");
  keycloak
    .updateToken(30)
    .then(() => {
      axios.defaults.headers.common = {
        Authorization: "Bearer " + keycloak.token
      };
      //console.log("Token refreshed");
    })
    .catch(() => {
      //console.log("Failed to refresh token");
      window.location.reload();
    });
};

try {
  keycloak.init({ onLoad: initOptions.onLoad, checkLoginIframe: false }).then((auth) => {

    if (!auth) {
      window.location.reload();
    }

    store.commit('setUserName', keycloak.idTokenParsed.user_name)
    store.commit(
      'setRoles',
      keycloak.tokenParsed.resource_access['fv-user-api'].roles
    )
    axios.defaults.baseURL = 'https://dashboard.svbefs.se/services/portal-api'
    axios.defaults.headers.common = {
      Authorization: 'Bearer ' + keycloak.token
    }
    Vue.config.productionTip = false
    Vue.use(VueAxios, axios)
    Vue.use(BootstrapVue)
    Vue.use(VueRouter)

    var router = new VueRouter({
      mode: 'history',
      routes: []
    })

    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  });
} catch (error) {

}