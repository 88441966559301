<template>
  <b-container fluid id="app">
    <div class="d-flex justify-content-center mb-3" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-else>
      <Overview :title="arrangement.title" :data="paidApplications" />
      <Checklist :title="arrangement.title" :data="paidApplications" :page="page" v-for="page in getPages()" v-bind:key="page" />
    </div>
  </b-container>
</template>

<script>
import Overview from './components/Overview.vue'
import Checklist from './components/Checklist.vue'

export default {
  name: 'app',
  components: {
    Overview,
    Checklist
  },
  mounted: function () {
    this.loadData()
  },
  data: function () {
    return {
      loading: true,
      arrangement: {},
      data: []
    }
  },
  computed: {
    isAuthorizedToRead: function () {
      return (
        this.$store.getters.roles.includes('arrangement_read') &&
        (this.$store.getters.roles.includes('applications_read') ||
          this.arrangement.organizers
            .map(o => o.organizer)
            .includes(this.$store.getters.userName))
      )
    },
    paidApplications: function () {
      return this.data.filter(item => item.total > 0)
    }
  },
  methods: {
    getArrangementId: function () {
      return Number(this.$route.query.arrangement)
    },
    getPages: function () {
      return this.$route.query.pages ? Number(this.$route.query.pages) : 0
    },
    loadData: function () {
      this.loading = true

      let arrangementId = this.getArrangementId()
      let accessPromise = new Promise((resolve, reject) => {
        if (!this.$store.getters.roles.includes('arrangement_read')) {
          reject(new Error('no permission to to fetch arrangement'))
        } else {
          this.axios
            .get('internal/arrangement/' + arrangementId)
            .then(response => {
              this.arrangement = response.data
              if (this.isAuthorizedToRead) {
                resolve()
              } else {
                reject(new Error('failed to fetch arrangement'))
              }
            })
            .catch(() => {
              reject(new Error('failed to fetch arrangement'))
            })
        }
      })

      accessPromise
        .then(() => {
          this.axios
            .get('internal/arrangement/' + arrangementId + '/application')
            .then(response => {
              let parsedData = []
              response.data.forEach(function (item) {
                parsedData.push({
                  group: item.id,
                  id: item.subscriber.id,
                  firstname: item.subscriber.firstName,
                  lastname: item.subscriber.lastName,
                  notMembers: item.nonSubscribers,
                  confirmed: item.confirmed,
                  reservedSlots:
                    (item.reserved ? 1 : 0) + item.reservedNonSubscriberSlots,
                  total: item.total
                })
              })
              this.data = parsedData
              this.loading = false
            })
            .catch(() => {
              console.log('Could not fetch applications.')
            })
        })
        .catch(() => {
          console.log('Could not fetch applications.')
        })
    }
  }
}
</script>

<style>
.row {
  padding-top: 10px;
  padding-bottom: 10px;
}
@media print {
  @page {
    size: auto;  margin: 0mm;
  }
}
</style>
